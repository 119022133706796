import { useState } from 'react';

import {
    scrollable,
    useResize,
    getBreakpointFromTheme
} from '../utils';

export const useMenuToggle = () => {
    const [ toggled, setToggled ] = useState<boolean>(false);
    const [ animated, setAnimated ] = useState<boolean>(false);
    const dimensions = useResize();
    const mobileBrakepoint = getBreakpointFromTheme("laptop");

    /* value: boolean = true */
    const toggleMenu = () => {
        setToggled(!toggled);
        const scroll = toggled ? 'enable' : 'disable';
        scrollable(scroll);
        
        setTimeout(function() {
            setAnimated(!animated);
        }, 1e3);
    };
    
    (dimensions.width >= mobileBrakepoint && toggled) ? toggleMenu() : null;

    return [
        { toggled, animated },
        { toggleMenu }
    ];
};