import React, { Fragment } from "react";
// import { IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../../hooks/useMetadata";
import urljoin from "url-join";

import Schema, { PostProps } from "./Schema";

interface SEOProps {
    slug: string | "/";
    lang?: string;
    title?: string;
    description?: string;
    noIndex?: boolean;

    postData?: PostProps;

    isMainPage?: boolean;
    isOrganization?: boolean;
    isSubPage?: boolean;
    isBlogPost?: boolean;
}

const getURL = (
    siteUrl: string,
    slug: string,
    defaultLang: string,
    lang: string
) => {
    return defaultLang === lang
        ? urljoin(siteUrl, slug)
        : urljoin(siteUrl, lang, slug);
};

const SEO: React.FC<SEOProps> = (props: SEOProps) => {
    const metadata = useSiteMetadata();

    const websiteName = metadata.name;
    const title = props.title || metadata.title;
    const defaultTitle = metadata.title;
    const description = props.description || metadata.description;
    const image = metadata.image;
    const language = props.lang || metadata.language;
    const url =
        getURL(
            metadata.siteUrl,
            props.slug,
            metadata.language,
            language
        ) || metadata.siteUrl;
    const siteUrl =
        getURL(
            metadata.siteUrl,
            '',
            metadata.language,
            language
        ) || metadata.siteUrl;

    return (
        <Fragment>
            <Helmet
                defer={false}
                htmlAttributes={{ lang: language }}
                title={title}
                defaultTitle={defaultTitle}
                titleTemplate={`%s | ${websiteName}`}
            >
                {/* General tags*/}
                <meta name="description" content={description} />
                <meta name="image" content={image} />
                {/* <link rel="canonical" href={url} /> */}
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {/* OpenGraph tags */}
                <meta property="og:url" content={url} />
                {props.isBlogPost ? (
                    <meta property="og:type" content="article" />
                ) : (
                    <meta property="og:type" content="website" />
                )}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:locale" content={language} />
                {props.isBlogPost ? (
                <meta property="og:image" content={props.postData.image} />
                ) : (
                <meta property="og:image" content={image} />
                )}
                {/* <meta property="fb:app_id" content={metadata.socialData.fbAppID} /> */}

                {/* Twitter Card tags*/}
                {/* <meta name="twitter:creator" content={metadata.socialData.twitter} /> */}
                {/* <meta name="twitter:card" content="summary" /> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:description" content={description} />
                {props.isBlogPost ? (
                    <meta name="twitter:image" content={props.postData.image} />
                ) : (
                    <meta name="twitter:image" content={image} />
                )}
                {props.postData?.timeToRead && <meta name="twitter:label1" content="Reading time" />}
                {props.postData?.timeToRead && <meta name="twitter:data1" content={props.postData.timeToRead + " min read"} />}

                {/* noindex meta tag */}
                {props.noIndex && <meta name="robots" content="noindex" />}
            </Helmet>

            {!props.noIndex && (
                <Schema
                    siteMetadata={metadata}
                    path={props.slug}
                    title={title}
                    description={description}
                    url={url}
                    siteUrl={siteUrl}
                    isOrganization={props.isOrganization ? props.isOrganization : false}
                    isWebSite={props.isMainPage ? props.isMainPage : false}
                    isWebPage={props.isSubPage ? props.isSubPage : false}
                    isBlogPost={props.isBlogPost ? props.isBlogPost : false}
                    isBreadcrumb={props.isBlogPost ? props.isBlogPost : false}
                    post={props.isBlogPost ? props.postData : null}
                />
            )}
        </Fragment>
    );
};

export default SEO;
