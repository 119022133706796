import { css } from "@emotion/core";

export interface CommonProps {
    color?: string;
    fontWeight?: string | number;
    textTransform?: string;
    textDecoration?: string;
    textAlign?: string;
    center?: string | boolean;
    flex?: string | number;
    margin?: string | number;
    padding?: string | number;
    display?: string;
    zIndex?: string | number;
    width?: string | number;
    height?: string | number;
    maxWidth?: string | number;
    maxHeight?: string | number;
    borderRadius?: string | number;
}

export const commonStyles = p => css`
    ${p.color && `color: ${p.color};`};
    ${p.fontWeight && `font-weight: ${p.fontWeight};`};
    ${p.textTransform && `text-transform: ${p.textTransform};`};
    ${p.textDecoration && `text-decoration: ${p.textDecoration};`};
    ${p.textAlign && `text-align: ${p.textAlign};`};
    ${p.center && `text-align: center;`};
    ${p.flex && `flex: ${p.flex};`};
    ${p.margin && `margin: ${p.margin};`};
    ${p.padding && `padding: ${p.padding};`};
    ${p.display && `display: ${p.display};`};
    ${p.zIndex && `z-index: ${p.zIndex};`};
    ${p.width && `width: ${p.width};`};
    ${p.maxWidth && `max-width: ${p.maxWidth};`};
    ${p.height && `height: ${p.height};`};
    ${p.maxHeight && `max-height: ${p.maxHeight};`};
    ${p.borderRadius && `border-radius: ${p.borderRadius};`};
`;