import styled from "@emotion/styled";
import mq from "@styles/media";
import tw from "twin.macro";

const Section = styled.section<{dark?: boolean; flex?: boolean; hero?: boolean}>`
    transition: ${p => p.theme.colorModeTransition};
    ${tw`clear-both py-16 m-0 mx-auto sm:py-20 md:py-24 lg:py-32 xl:py-40`};
    
    ${p => p.dark && `
        background-color: ${p.theme.colors.backgroundDark};
    `};

    ${p => p.flex && tw`flex flex-col items-center md:flex-row`};
    ${p => p.hero && tw`py-24 md:h-screen sm:py-24 md:py-0 lg:py-0 xl:py-0 md:mb-20 lg:mb-24 xl:mb-32`};
    ${p => p.hero && mq.from_tablet_ipad`min-height: 600px;`};
`;

export default Section;
