import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Image from "./Image";

import { IImg } from "@types";

const ImageStatic: React.FC<IImg> = ({ src, alt, ...props }) => (
    <StaticQuery
        query={graphql`
            query {
                allImageSharp {
                    edges {
                        node {
                            fluid(maxWidth: 600, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                                originalName
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const image = data.allImageSharp.edges.find(
                edge => edge.node.fluid.originalName === src
            );
            if (!image) {
                return null;
            }

            return <Image src={image.node.fluid} alt={alt} {...props} />;
        }}
    />
);
export default ImageStatic;
