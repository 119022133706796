import React from 'react';
import styled from '@emotion/styled';
import mq from '@styles/media';

import { useSiteMetadata } from '../../hooks/useMetadata';

import Wrapper from '../Wrapper';
import Anchor from '../Anchor';
import Button from '../Button';
import Link from '../Link';
import Image from '../Image/Image.Static';
import SocialLinks from '../SocialLinks';
import tw from 'twin.macro';


const Footer: React.FC<{}> = () => {
    const data = useSiteMetadata();
    const name = data.author.name;
    const social = data.social;

    const copyrightDate = new Date().getFullYear();

    return (
        <StyledFooter>
            <Wrapper>
                <FlexContainer>
                    <LeftFlexBox>
                        <Logo src="ifaktor-agency.png" />
                        {/* <LogoText>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam, ad. Natus explicabo ipsam reprehenderit modi iusto harum, amet, minus nostrum laborum placeat quisquam. Quis, illo.</LogoText> */}
                    </LeftFlexBox>
                    <FlexBox>
                        <Box>
                            <Header>Oldalak</Header>
                            <StyledList>
                                <li><Anchor as={Link} to="/influencers">Influenszer Lista</Anchor></li>
                                <li><Anchor as={Link} to="/">Főoldal</Anchor></li>
                                <li><Anchor as={Link} to="/#about">Rólunk</Anchor></li>
                                <li><Anchor as={Link} to="/#goal">Küldetésünk</Anchor></li>
                                <li><Anchor as={Link} to="/#szolgaltatasaink">Szolgáltatásaink</Anchor></li>
                                <li><Anchor as={Link} to="/#media">Médiánk</Anchor></li>
                            </StyledList>
                        </Box>
                        <Box>
                            <Header>Közösség</Header>
                            <StyledList>
                                {/* <li><Anchor as={Link} to="/">Blog</Anchor></li>
                                <li><Anchor as={Link} to="/">Discussions</Anchor></li>
                                <li><Anchor as={Link} to="/">Collections</Anchor></li>
                                <li><Anchor as={Link} to="/">Members</Anchor></li>
                                <li><Anchor as={Link} to="/">Products</Anchor></li>
                                <li><Anchor as={Link} to="/">Help Center</Anchor></li> */}
                                <li><Anchor as={Link} to="/blog">Blog</Anchor></li>
                                {/* <li><Anchor as={Link} to="/tehetsegkutato">iFaktor Tehetségkutató</Anchor></li> */}
                                <li><Anchor as={Link} to="/adatvedelem">Adatvédelmi Szabályzat</Anchor></li>
                                <li><Anchor as={"a"} href="/Versenyszabalyzat_TikTok_210427.pdf" download>Tehetségkutató Versenyszabályzat</Anchor></li>
                                {/* <li><Anchor as={Link} to="/">Privacy Policy</Anchor></li> */}
                            </StyledList>
                        </Box>
                        <Box social>
                            <Header>Kapcsolat</Header>
                            <SocialLinks links={social} margin="20px 0"/>
                            <NavButton as={Link} to="/">Írj nekünk</NavButton>
                            {/* <NavButton as={Link} to="/contact/">Get Started</NavButton> */}
                        </Box>
                    </FlexBox>
                </FlexContainer>
            </Wrapper>
            <FooterContainer>
                <FooterText>
                    © {copyrightDate} All rights reserved.
                    {/* &nbsp;–&nbsp; */}
                    {/* <Anchor
                        key={data.author.url}
                        href={data.author.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-a11y="false"
                        aria-label={`Link to ${data.author.url}`}
                    >Designed&nbsp;&amp;&nbsp;Coded by {name}</Anchor> */}
                </FooterText>
            </FooterContainer>
      </StyledFooter>
  );
};

export default Footer;

const Header = styled.h3`
    ${tw`mb-4 text-lg font-bold`};
    color: ${p => p.theme.colors.secondaryAccent};
`;

const LogoText = styled.p`
    ${tw`max-w-full my-4 text-sm leading-normal text-left text-gray-700 md:text-base`};

    ${mq.from_laptop`
        max-width: 70%;
    `};
`;

const NavButton = styled(Button)`
    ${tw`px-6 py-2`};
`;

const StyledFooter = styled.footer`
    background: ${p => p.theme.colors.backgroundPair};
    transition: ${p => p.theme.colorModeTransition};
`;

const FlexContainer = styled.div`
    ${tw`flex-row flex-no-wrap justify-between flex-grow block py-16 md:flex`};
`;

const FlexBox = styled.div`
    ${tw`flex flex-wrap`};
    flex: 1 1 auto;
    justify-content: stretch;
    
    ${mq.from_mobile_i6p`
        justify-content: space-between;
        flex-wrap: nowrap;
    `};
    ${mq.from_desktop`
        flex: 1 1 0;
    `}
`;

const LeftFlexBox = styled(FlexBox)`
    ${tw`block pr-3 mb-6 md:mb-0`};
    flex: 35% 1;
`;

const Logo = styled(Image)`
    max-width: 250px;
`;

const Box = styled.div<{ social?: boolean }>`
    ${tw`block px-3 py-0 mx-0 my-3`};
    width: 50%;
    ${mq.from_mobile_i6p`
        width: unset;
    `};

    ${p => p.social && tw`w-full`};
    ${p => p.social && mq.from_mobile_i6p`
        width: unset;
    `};
`;

const StyledList = styled.ul`
    ${tw`block list-none`};

    & li {
        ${tw`mx-0 my-2`};

        & ${Anchor} {
            font-size: calc(${p => p.theme.fontSizes.base} * 0.8);
            color: ${p => p.theme.colors.grey};

            ${mq.from_tablet`
                font-size: calc(${p => p.theme.fontSizes.base} * 0.9);
            `};
        }
    }
`;

const FooterContainer = styled.div`
    ${tw`block py-6 md:flex md:flex-col md:relative md:justify-between`};
    background-color: ${p => p.theme.colors.background};
    transition: ${p => p.theme.colorModeTransition};

    ${Anchor} {
        color: ${p => p.theme.colors.secondaryAccent};
    }
`;

const FooterText = styled.p`
    ${tw`mx-auto my-0 text-xs text-center md:mb-0`};
    transition: ${p => p.theme.transitionValue};
    color: ${p => p.theme.colors.grey};
`;