import { keyframes } from "@emotion/core";

export const FadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const FadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
      }
`;

export const FadeInZoom = keyframes`
    0% {
        opacity: 0;
        transform: scale(.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

export const Float = keyframes`
    0% {
        transform: translateY(10px);
    }
    to {
        transform: translateY(-10px);
    }
`;

export const DropPulse = (color: string, fadedColor: string) => keyframes`
    from {
        filter: drop-shadow(0px 0px 0px ${color});
    }
    to {
        filter: drop-shadow(0px 0px 30px ${fadedColor});
    }
`;

export const BoxPulse = (color: string, fadedColor: string) => keyframes`
    from {
        box-shadow: 0 0 0 0px ${color};
    }
    to {
        box-shadow: 0 0 0 20px ${fadedColor};
    }
`;

export const Wiggle = keyframes`
    0% {
        transform: rotate(-0.5deg);
    }
    4% {
        transform: rotate(0.5deg);
    }
    8% {
        transform: rotate(-1deg);
    }
    12% {
        transform: rotate(0.5deg);
    }
    16% {
        transform: rotate(0deg);
    }
`;