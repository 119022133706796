import React from "react";
import { Helmet } from "react-helmet";
import urljoin from "url-join";

import {
    ListItem,
    Organization,
    WithContext,
    WebSite,
    WebPage,
    Thing,
    BreadcrumbList,
    BlogPosting,
    ImageObject,
    Person,
} from "schema-dts";

type siteMetadataProps = {
    organization: {
        url: string;
        logo: string;
        name: string;
    };
    siteUrl: string;
    title: string;
    description: string;
    language: string;
};

export type PostProps = {
    language: string;
    url: string;
    name: string;
    alternateName: string;
    title: string;
    description: string;
    date: string;
    modifyDate: string;
    image: string;
    author: string;
    wordcount: number;
    timeToRead: string;
};

interface SchemaProps {
    siteMetadata: siteMetadataProps;
    post?: PostProps;

    // For WebPage data
    url: string;
    siteUrl: string;
    path: string;
    title: string;
    description: string;

    isOrganization: boolean;
    isWebSite: boolean;
    isWebPage: boolean;
    isBlogPost: boolean;
    isBreadcrumb: boolean;
}

export const getSchemaBreadcrumbs = (pathname: string, siteUrl: string) => {
    let paths = pathname.split("/");

    // remove the last element if there was a / at the end of the pathname
    paths =
        paths[paths.length - 1] === ""
            ? paths.slice(0, paths.length - 1)
            : paths;

    const breadcrumbs = paths
        .map((path, index) => {
            // The first element should be skipped
            if (index === 0) {
                return null;
            }

            // Build the path for the current URL
            let breadcrumb_url = paths.slice(0, index + 1).join("/");

            let breadcrumb: Thing = {
                "@type": "Thing",
                "@id": urljoin(siteUrl, breadcrumb_url),
                url: urljoin(siteUrl, breadcrumb_url),
                // name: props.name || '', // should be able to get info of the page and insert name/title of it
                // image: props.image || '', // should be able to get info of the page and insert image of page
            };

            let data: ListItem = {
                "@type": "ListItem",
                item: breadcrumb,
                position: index,
            };

            return data;
        })
        .filter(v => v !== null);

    if (breadcrumbs.length) {
        const breadcrumbData: BreadcrumbList = {
            "@type": "BreadcrumbList",
            numberOfItems: breadcrumbs.length,
            itemListElement: breadcrumbs,
        };
        const breadcrumbListSchema: BreadcrumbList = { ...breadcrumbData };

        return breadcrumbListSchema;
    } else {
        return null;
    }
};

/**
 * Rules for using Schema.org Rich Snippets:
 * - Use WebSite on the home page of the website
 * - Use WebPage on pages that have extra info to offer: services page, pages with information
 *      - Use BreadcrumbList and ListItem if they are nested pages (i.e.: https://example.com/services/my-first-service -> 2 breadcrumbs
 *        with /services/ and /services/my-first-service)
 * - Use Organization in itself on home page, contact or about pages
 * - Use Blog on the main page of the blog
 * - Use BlogPosting for blog posts
 * - Use Product on product pages
 */
const Schema: React.FC<SchemaProps> = (props: SchemaProps) => {
    // "Organization" Schema
    const organizationSchema: Organization = {
        "@type": "Organization",
        url: props.siteMetadata.organization.url,
        logo: props.siteMetadata.organization.logo,
        name: props.siteMetadata.organization.name,
    };
    const contextOrganizationSchema: WithContext<Organization> = {
        "@context": "https://schema.org",
        ...organizationSchema,
    };

    // "WebSite" Schema
    const websiteSchema: WebSite = {
        "@type": "WebSite",
        "@id": props.siteUrl + "#website",
        url: props.siteUrl,
        name: props.siteMetadata.title,
        alternateName: props.siteMetadata.title,
        inLanguage: props.siteMetadata.language, // Could be Language Schema
        publisher: organizationSchema,
    };
    const contextWebsiteSchema: WithContext<WebSite> = {
        "@context": "https://schema.org",
        ...websiteSchema,
    };

    // "WebPage" Schema
    let webpageBase: WebPage = {
        "@type": "WebPage",
        "@id": props.url + "#website",
        url: props.url,
        name: props.siteMetadata.title,
        alternateName: props.siteMetadata.title,
        description: props.description,
        inLanguage: props.siteMetadata.language, // Could be Language Schema
        publisher: organizationSchema,
        // provider: props.organization,
        // primaryImageOfPage: props.siteMetadata.image OR ImageObject
    };

    const breadcrumbs = getSchemaBreadcrumbs(props.path, props.siteUrl);
    const webpageSchema: WebPage = breadcrumbs
        ? { ...webpageBase, breadcrumb: breadcrumbs }
        : webpageBase;
    const contextWebpageSchema: WithContext<WebPage> = {
        "@context": "https://schema.org",
        ...webpageSchema,
    };

    // "BreadcrumbList" Schema
    const contextBreadcrumbListSchema: WithContext<BreadcrumbList> = {
        "@context": "https://schema.org",
        ...breadcrumbs,
    };

    const schema = [];

    props.isOrganization ? schema.push(contextOrganizationSchema) : null;
    props.isWebSite ? schema.push(contextWebsiteSchema) : null;
    props.isWebPage ? schema.push(contextWebpageSchema) : null;
    props.isBreadcrumb && contextBreadcrumbListSchema
        ? schema.push(contextBreadcrumbListSchema)
        : null;

    // "BlogPosting" Schema
    if (props.post && props.isBlogPost) {
        const image: ImageObject = {
            "@type": "ImageObject",
            url: props.post.image,
        };

        const person: Person = {
            "@type": "Person",
            name: props.post.author,
        };

        const blogPostingSchema: WithContext<BlogPosting> = {
            "@type": "BlogPosting",
            "@context": "https://schema.org",
            url: props.post.url,
            name: props.post.name,
            alternateName: props.post.alternateName,
            headline: props.post.title,
            description: props.post.description,
            dateCreated: props.post.date,
            datePublished: props.post.date,
            dateModified: props.post.modifyDate,
            wordCount: props.post.wordcount,
            image: image,
            author: person,
            inLanguage: props.post.language,
            publisher: organizationSchema,
            mainEntityOfPage: websiteSchema,
        };

        schema.push(blogPostingSchema);
    }

    return (
        <Helmet>
            {/* Schema.org tags */}
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    );
};

Schema.defaultProps = {
    isOrganization: false,
    isWebSite: false,
    isWebPage: false,
    isBlogPost: false,
    isBreadcrumb: false,
};

export default React.memo(Schema);
