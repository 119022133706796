import React from 'react';
import { globalHistory } from '@reach/router';

import styled from '@emotion/styled';
import mq from '@styles/media';

import { useMenuToggle } from '../../hooks/useMenuToggle';
import { useSiteMetadata } from '../../hooks/useMetadata';

import { FadeIn } from '../Transitions/keyframes';
import Wrapper from '../Wrapper';
import Link from '../Link';
import Image from '../Image/Image.Static';
import Button from '../Button';

import NavigationHeaderMobile from './Navigation.Header.Mobile';
// import SharePageButton from './components/Navigation.ShareButton';
// import DarkModeToggle from './components/Navigation.ThemeToggle';
import HamburgerButton from './components/Navigation.HamburgerButton';

import { useScrollPosition } from '@utils';
import tw from 'twin.macro';



const NavigationHeader: React.FC<{ toggleScroll?: boolean }> = ({ toggleScroll=false }) => {
    const metadata = useSiteMetadata();
    const basePath = metadata.basePath;

    const { location } = globalHistory;
    const scrolled = useScrollPosition();
    const [
        { toggled, animated },
        { toggleMenu },
    ] = useMenuToggle();
    

    // For Inverted Boolean
    const inverted = toggleScroll ? !Boolean(scrolled) : false;
    
    // To Check whether a MenuItem is Active
    const isActive = (path: string) => location.pathname.includes(path);

    return (
    <Header>
        <Nav inverted={ inverted }>
            <NavWrapper>
                <LogoContainer>
                    <LogoLink
                        to={basePath}
                        data-a11y="false"
                        title="Navigate back to the homepage"
                        aria-label="Navigate back to the homepage"
                    >
                        <Image src="ifaktor-agency.png" loading="eager" fadeIn={false} critical={true} />
                    </LogoLink>
                </LogoContainer>
                <NavigationSection>
                    <Menu>
                        {/* <MenuItem>
                            <MenuLink inverted={ inverted } to="/#about">Rólunk</MenuLink>
                        </MenuItem> */}
                        
                        {/* <MenuItem>
                            <MenuLink inverted={ inverted } to="/#goal">Küldetésünk</MenuLink>
                        </MenuItem> */}

                        {/* <MenuItem active={ isActive('home') }>
                            <MenuLink inverted={ inverted } to="/#influenszerek">Influenszerek</MenuLink>
                        </MenuItem> */}

                        <MenuItem /* active={ isActive('szolgaltatasaink') } */>
                            <MenuLink inverted={ inverted } to="/#szolgaltatasaink">Szolgáltatásaink</MenuLink>
                        </MenuItem>

                        {/* <MenuItem>
                            <MenuLink inverted={ inverted } to="/#projects">Munkák</MenuLink>
                        </MenuItem> */}

                        <MenuItem /* active={ isActive('about') } */>
                            <MenuLink inverted={ inverted } to="/#media">Médiánk</MenuLink>
                        </MenuItem>

                        <MenuItem active={ isActive('influencers') }>
                            <MenuLink inverted={ inverted } to="/influencers/">Influenszer Lista</MenuLink>
                        </MenuItem>

                        <MenuItem active={ isActive('adatvedelem') }>
                            <MenuLink inverted={ inverted } to="/adatvedelem/">Adatvédelem</MenuLink>
                        </MenuItem>
                        
                        <MenuItem active={ isActive('blog') }>
                            <MenuLink inverted={ inverted } to="/blog">Blog</MenuLink>
                        </MenuItem>
                        
                        {/* <MenuItem>
                            <MenuLink inverted={ inverted } to="/#team">Csapat</MenuLink>
                        </MenuItem> */}

                    </Menu>
                 </NavigationSection>
                {/* <NavControls>
                    <SharePageButton />
                    <DarkModeToggle inverted={ inverted } />
                </NavControls> */}
                <NavigationSection>
                    <NavControls>
                        <NavButton as="a" href="/z-generacio-a-tiktok-es-az-influencerek-buvoleteben.pdf" secondary download>Kutatás</NavButton>
                        { inverted ? 
                            <NavButton as="a" href="mailto:info@ifaktor.hu">Kapcsolat</NavButton>
                            // <NavButton as={Link} to="/contact/">Kapcsolat</NavButton>
                            :
                            <NavButton as="a" href="mailto:info@ifaktor.hu">Kapcsolat</NavButton>
                            // <NavButton as={Link} to="/contact/">Kapcsolat</NavButton>
                        }
                </NavControls>
                </NavigationSection>
                <HamburgerButton toggled={toggled} inverted={inverted} toggleMenu={toggleMenu} />
            </NavWrapper>
        </Nav>
        <NavigationHeaderMobile toggled={toggled} animated={animated} toggleMenu={toggleMenu} />
    </Header>
  );
};

export default NavigationHeader;


const Header = styled.header`
    ${tw`z-50`};
    animation: ${FadeIn} 1s both;
`;

const Nav = styled.nav<{ inverted?: boolean }>`
    ${tw`fixed z-50 w-full`};
    height: 70px;
    background-color: ${p => p.theme.colors.background};
    backdrop-filter: blur(6px);
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    
    // One or the other
    border-bottom: 1px solid ${p => p.theme.colors.hover};
    // box-shadow: 0px 5px 15px 0px ${p => p.theme.colors.boxShadow};
    
    ${p => p.inverted && tw`bg-transparent border-none shadow-none`};
`;

const NavWrapper = styled(Wrapper)`
    ${tw`flex flex-row items-center justify-end`};
    height: 70px;
`;

const LogoContainer = styled.div`
    ${tw`z-20 flex-grow block hover:opacity-75`};
    transition: ${p => p.theme.transitionValue};
`;

const LogoLink = styled(Link)`
    ${tw`relative block h-full`};
    width: 175px;
`;

const NavigationSection = styled.div`
    ${tw`z-20 m-0`};
    display: none;
    
    ${mq.from_laptop`
        display: block;
    `};
`;

const Menu = styled.ul`
    ${tw`flex flex-row items-center justify-center justify-self-center`};
`;

const MenuItem = styled.li<{ active?: boolean }>`
    ${tw`block m-0 mx-2 xl:mx-3 hover:opacity-50 last:mr-8`};
    transition: ${p => p.theme.transitionValue};

    ${p => p.active && `
        ${MenuLink} {
            font-weight: ${p.theme.fontWeights.bold};
            color: ${p.theme.colors.accent};
        }
    `};
`;

const MenuLink = styled(Link)<{ inverted?: boolean }>`
    ${tw`block p-3 text-sm font-semibold text-center text-gray-800`};
    transition: ${p => p.theme.transitionValue};
    color: ${p => p.theme.colors.primary};
`;

const NavControls = styled.div`
    ${tw`relative flex items-center flex-row`};
`;

const NavButton = styled(Button)`
    ${tw`px-6 py-2`};
    ${p => p.secondary && `background-color: ${p.theme.colors.accent};`};
    &:first-of-type {
        ${tw`mr-4`};
    }
`;