import React, { useEffect } from "react";
import { Global } from "@emotion/core";
import styled from "@emotion/styled";
import { useColorMode } from "theme-ui";

import NavigationHeader from "../Navigation/Navigation.Header";
import NavigationFooter from "../Navigation/Navigation.Footer";

import { globalStyles } from "../../styles";

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<{}> = ({ children }) => {
    const [colorMode] = useColorMode();

    useEffect(() => {
        parent.postMessage({ theme: colorMode }, "*");
    }, [colorMode]);

    return (
        <Container>
            <Global styles={globalStyles} />
            <NavigationHeader toggleScroll={true} />
            <main>{children}</main>
            <NavigationFooter />
        </Container>
    );
};

export default Layout;

const Container = styled.div`
    position: relative;
    background: ${p => p.theme.colors.background};
    transition: ${p => p.theme.colorModeTransition};
    min-height: 100vh;
    // overflow: hidden; // removed style because of position: sticky; for child elements
`;
