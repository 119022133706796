import React from 'react';
import { globalHistory } from '@reach/router';
import styled from '@emotion/styled';
import tw from 'twin.macro';

import { useSiteMetadata } from '../../hooks/useMetadata';

import Button from '../Button';
import Link from '../Link';
import SocialLinks from '../SocialLinks/SocialLinks';

interface MobileHeaderProps {
    toggled: boolean;
    animated: boolean;
    toggleMenu: (value?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const NavigationHeaderMobile: React.FC<MobileHeaderProps> = ({
    toggled = false,
    animated = false,
    toggleMenu = () => {}
}) => {
    const { location } = globalHistory;

    // To Check whether a MobileMenuItem is Active
    const isActive = (path) => location.pathname.includes(path);

    return (
        <MobileNavContainer active={ toggled } animation={ animated }>
            {/*<Background />*/}
            <MobilePopup>
                <MobileNav>

                    <MobileMenuContainer animation={ animated }>
                        <MobileMenu active={ toggled }>
                            <MobileMenuItem active={ toggled } animation={ animated } index={0}>
                                <Link to="/#about" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Rólunk</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            {/* <MobileMenuItem active={ toggled } animation={ animated } index={0}>
                                <Link to="/#goal" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Küldetésünk</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem> */}

                            {/* <MobileMenuItem active={ toggled } animation={ animated } index={1}>
                                <Link to="/#influenszerek" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Influenszerek</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem> */}

                            <MobileMenuItem active={ toggled } animation={ animated } index={1}>
                                <Link to="/#szolgaltatasaink" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Szolgáltatásaink</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            {/* <MobileMenuItem active={ toggled } animation={ animated } index={2}>
                                <Link to="/#projects" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Munkák</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem> */}

                            <MobileMenuItem active={ toggled } animation={ animated } index={3}>
                                <Link to="/#media" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Médiánk</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            {/* <MobileMenuItem active={ toggled } animation={ animated } index={4}>
                                <Link to="/#team" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Csapat</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem> */}

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('influencers') } index={4}>
                                <Link to="/influencers/">
                                    <MenuLinkParagraph>Influenszer Lista</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>
                            
                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('adatvedelem') } index={5}>
                                <Link to="/adatvedelem/">
                                    <MenuLinkParagraph>Adatvédelmi Szabályzat</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('blog') } index={6}>
                                <Link to="/blog" onClick={ toggleMenu }>
                                    <MenuLinkParagraph>Blog</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem active={ toggled } animation={ animated } selected={ isActive('contact') } index={7}>
                                <Link to="/influencers/#contact">
                                {/* <Link to="/contact/"> */}
                                    <MenuLinkParagraph>Kapcsolat</MenuLinkParagraph>
                                </Link>
                            </MobileMenuItem>
                        </MobileMenu>
                    </MobileMenuContainer>

                    <MobileContactContainer active={ toggled } animation={ animated }>
                        <ContactButton as="a" href="z-generacio-a-tiktok-es-az-influencerek-buvoleteben.pdf" secondary download>Kutatás</ContactButton>
                        <ContactButton as="a" href="mailto:info@ifaktor.hu">Kapcsolat</ContactButton>
                        <SocialLinks center links={useSiteMetadata().social} />
                    </MobileContactContainer>

                </MobileNav>
            </MobilePopup>
        </MobileNavContainer>
    );
}

export default NavigationHeaderMobile;

// const Background = styled.div`
//     position: absolute;
//     // width: 100%;
//     height: 100%;
//     margin: 0;
//     padding: 0;
//     top: 0px;
//     left: 0px;
//     min-width: 100%;
//     min-height: 100%;
//     background: ${p => p.theme.colors.background};
//     opacity: .90;
//     backdrop-filter: blur(4px);
// `;

/**
 * Thanks to Daniel Korpai
 * Menu Inspiration from http://danielkorpai.com/
*/
const MobileNavContainer = styled.div<{ active?: boolean, animation?: boolean }>`
    ${tw`fixed top-0 left-0 z-40 block w-full h-0 overflow-x-hidden overflow-y-visible scrolling-touch opacity-100 pointer-events-none`};
    background: ${p => p.theme.colors.background};
    transition: .56s cubic-bezier(0.52, 0.16, 0.24, 1);
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    ${p => p.active && tw`h-full opacity-100 cursor-pointer pointer-events-auto`};

    ${p => p.animation && `
        transition-delay: 550ms;
    `};
`;
    
const MobilePopup = styled.div`
    ${tw`absolute left-0 right-0 z-20 block w-full h-full m-0 mx-auto bg-transparent opacity-100 pointer-events-auto`};
`;

const MobileNav = styled.div`
    ${tw`flex flex-col justify-between`};
    padding: 0 4%;
    top: 70px;
    height: calc(100% - 70px);
`;

const MobileMenuContainer = styled.div<{ animation?: boolean }>`
    ${tw`flex flex-col justify-center flex-grow m-0`};
`;

const MobileMenu = styled.ul<{ active?: boolean }>`
    ${tw`m-0 list-none`};
`;

const MobileMenuItem = styled.li<{ index: number, active?: boolean, animation?: boolean, selected?: boolean }>`
    ${tw`mb-3 sm:mb-4 md:mb-5 last:mb-0`};

    ${p => p.selected && `
        & a, p {
            color: ${ p.theme.colors.accent };
        }
    `};

    ${p => p.active ? `
        // Reverse Animation/State
        opacity: 1;
        transform: none;
    `: `
        // Normal Animation/State
        opacity: 0;
        transform: translateY(-30px);
    `};

    ${p => p.animation ? `
        // Reverse Animation/State
        transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition-delay: calc(500ms - (25ms * ${p.index}));
    ` : `
        // Normal Animation/State
        transition: all 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition-delay: calc(400ms + (50ms * ${p.index}));
    `};
`;

const MenuLinkParagraph = styled.p`
    ${tw`py-2 mb-0 text-lg font-bold leading-none sm:py-3 sm:text-xl md:text-2xl`};
`;

const MobileContactContainer = styled.div<{ active?: boolean, animation?: boolean }>`
    ${tw`mx-0 mb-5 sm:mb-6`};
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    
    ${p => p.active ? `
        // Reverse Animation/State
        opacity: 1;
        transform: none;
    `: `
        // Normal Animation/State
        opacity: 0;
        transform: translateY(-15px);
    `};

    ${p => p.animation ? `
        // Reverse Animation/State
        transition-delay: 50ms;
    ` : `
        // Normal Animation/State
        transition-delay: 700ms;
    `};

    
`;

const ContactButton = styled(Button)`
    ${tw`block py-3 mb-3 font-bold text-center sm:py-4 sm:mb-4`};
    ${p => p.secondary && `background-color: ${p.theme.colors.accent};`};
`;