import styled from "@emotion/styled";
import mq from "@styles/media";
import tw from "twin.macro";

const Wrapper = styled.div<{ left?: boolean; right?: boolean }>`
    ${tw`block m-0 mx-auto z-2`};
    padding: 0 4%;

    ${mq.from_laptop`
        padding: 0;
        max-width: 900px;
    `};
    
    ${mq.from_desktop`
        max-width: 1100px;
    `};
    
    ${mq.from_desktop_big`
        max-width: 1200px;
    `};
    
    ${p => p.left && mq.from_laptop`padding-left: calc((100% - 900px) / 2);`};
    ${p => p.left && mq.from_desktop`padding-left: calc((100% - 1100px) / 2);`};
    ${p => p.left && mq.from_desktop_big`padding-left: calc((100% - 1200px) / 2);`};

    ${p => p.right && mq.from_laptop`padding-right: calc((100% - 900px) / 2);`};
    ${p => p.right && mq.from_desktop`padding-right: calc((100% - 1100px) / 2);`};
    ${p => p.right && mq.from_desktop_big`padding-right: calc((100% - 1200px) / 2);`};
    
    ${p => p.left && tw`text-center md:mx-0 md:mr-auto md:items-start md:text-left md:w-6/12 lg:w-5/12`};
    ${p => p.right && tw`text-center md:mx-0 md:ml-auto md:items-start md:text-left md:w-6/12 lg:w-5/12`};
`;

export default Wrapper;
