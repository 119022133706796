import styled from "@emotion/styled";
import tw from "twin.macro";

const Button = styled.button<{primary?: boolean; secondary?: boolean}>`
    ${tw`px-8 py-3 font-semibold text-white border border-gray-400 rounded shadow hover:bg-teal-500`};
    background-color: ${p => p.theme.colors.secondaryAccent};
    transition: ${p => p.theme.transitionValue};
`;

export default Button;
