import React from 'react';
import styled from '@emotion/styled';
import mq from '@styles/media';

const Hamburger: React.FC<{
    toggled: boolean;
    inverted: boolean;
    toggleMenu: (event?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void;
}> = ({
    toggled = false,
    inverted = false,
    toggleMenu = () => {}
}) => {
    return (
        <HamburgerButton
            onClick={ toggleMenu }
            onKeyDown={ toggleMenu }
            aria-label={`Toggle Menu`}
            active={ toggled }
            role="navigation"
        >
            <HamburgerIcon aria-hidden="true">
                <HamburgerLine number={1} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
                <HamburgerLine number={2} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
                <HamburgerLine number={3} active={ toggled } inverted={ inverted } aria-hidden="true"></HamburgerLine>
            </HamburgerIcon>
        </HamburgerButton>
    );
};

export default Hamburger;

const HamburgerButton = styled.button<{ active?: boolean }>`
    display: block;
    width: 40px;
    height: 40px;
    margin: 0;
    margin-right: -10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: none;
    
    ${mq.from_laptop`
        display: none;
        visibility: hidden;
        opacity: 0;
    `};

    ${p => p.active && `
        transform: rotate(90deg);
    `};
`;

const HamburgerIcon = styled.div`
    position: absolute;
    top: 13px;
    right: 0;
    left: 0;
    padding: 0;
    display: block;
    margin: 0 auto;
`;

const HamburgerLine = styled.span<{ number?: number, active?: boolean, inverted?: boolean }>`
    right: 0;
    left: 0;
    padding: 0;
    display: block;
    margin: 0 auto;

    width: 20px;
    height: 2px;
    background-color: ${p => p.theme.colors.primary};
    border-radius: 10px;
    transition: 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: none;
    position: absolute;

    ${p => p.inverted && `
        // background-color: ${p.theme.colors.secondary};

        ${p.active && `
            background-color: ${p.theme.colors.primary};
        `};
    `};

    ${p => p.active ? `
        ${p.number === 1 ? `
            transform: rotate(45deg);
            margin-top: 6px;
        `: ``};

        ${p.number === 2 ? `
            width: 0;
        ` : ``};

        ${p.number === 3 ? `
            transform: rotate(-45deg);
            margin-top: 6px;
        ` : ``};
    ` : `
        ${p.number === 2 ? ` margin-top: 6px; ` : ``};
        ${p.number === 3 ? ` margin-top: 12px; ` : ``};
    `};
`;
