import { useStaticQuery, graphql } from 'gatsby';
import { ISiteMetadata } from '../types';


const useSiteMetadata = () => {
    const result: { site: { siteMetadata: ISiteMetadata }} = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    siteUrl
                    basePath
                    title
                    name
                    author {
                        name
                        url
                        logo   
                    }
                    description
                    image
                    organization {
                        name
                        url
                        logo
                    }
                    social {
                        name
                        url
                    }
                    i18n
                    language
                    languages
                }
            }
        }
    `);

    return result.site.siteMetadata;
};

export default useSiteMetadata;