import React from "react";
import styled from "@emotion/styled";
import { commonStyles, CommonProps } from "@styles/common";

import Anchor from "../Anchor";
import tw from "twin.macro";

interface SocialLinksProps {
    links: {
        name: string;
        url: string;
    }[];
    fill?: string;
}

const icons = {
    twitter: "twitter.inline.svg",
    facebook: "facebook.inline.svg",
    instagram: "instagram.inline.svg",
    medium: "medium.inline.svg",
    dribbble: "dribbble.inline.svg",
    github: "github.inline.svg",
    linkedin: "linkedin.inline.svg",

};

// const icons = {
//     twitter: Icons.Twitter,
//     facebook: Icons.Facebook
// };

const getHostname = url => {
    return new URL(url.toLowerCase()).hostname
        .replace("www.", "")
        .split(".")[0];
};

const getServicename = url => {
    return url.toLowerCase().split(':')[0];
};

const SocialLinks: React.FC<SocialLinksProps & CommonProps> = ({
    links,
    fill = "#73737D",
    ...props
}) => {
    if (!links) return null;

    return (
        <StyledList { ...props }>
            {links.map((option, index) => {
                const name = option.name || getHostname(option.url) || getServicename(option.url);
                // const Icon = icons[name];
                const Icon = require("@images/socials/" + icons[name]);
                const StyledIcon = styled(Icon)`
                    & * {
                        fill: ${fill};
                    }
                `;

                if (!Icon) {
                    throw new Error(
                        `unsupported social link name=${name} / url=${option.url}`
                    );
                }
                return (
                    <SocialIconContainer>
                        <StyledAnchor
                            target="_blank"
                            rel="noopener nofollow"
                            data-a11y="false"
                            aria-label={`Link to ${option.url}`}
                            href={option.url}
                            key={index.toString()}
                        >
                            <StyledIcon />
                            <Hidden>Link to ${option.url}</Hidden>
                        </StyledAnchor>
                    </SocialIconContainer>
                );
            })}
        </StyledList>
    );
};

export default SocialLinks;

const StyledList = styled.ul`
    ${commonStyles};
`;

const StyledAnchor = styled(Anchor)`
    ${tw`inline-flex flex-row items-center justify-start w-auto rounded-md opacity-50 hover:opacity-100 focus:opacity-100`};
    transition: ${p => p.theme.transitionValue};

    &:hover, &:focus {
        svg * {
            fill: ${p => p.theme.colors.accent};
            transition: fill 0.25s var(--ease-in-out-quad);
        }
    }

    &[data-a11y="true"]:focus::after {
        content: "";
        position: absolute;
        left: -50%;
        top: -20%;
        width: 200%;
        height: 160%;
        border: 2px solid ${p => p.theme.colors.accent};
        background: rgba(255, 255, 255, 0.01);
        border-radius: 5px;
    }
`;

const Hidden = styled.span`
    ${tw`invisible inline-block w-0 h-0 overflow-hidden opacity-0`};
`;

const SocialIconContainer = styled.li`
    ${tw`inline-block`};

    & ${StyledAnchor} {
        ${tw`px-1 py-2 mx-1`};
    }
    
    &:first-of-type ${StyledAnchor} {
        ${tw`pl-0 ml-0`};
    }

    &:last-of-type ${StyledAnchor} {
        ${tw`pr-0 mr-0`};
    }
`;