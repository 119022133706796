import styled from "@emotion/styled";
import { commonStyles, CommonProps } from "@styles/common";

const Anchor = styled.a<CommonProps & {underline?: boolean; arrowed?: boolean}>`
    transition: ${p => p.theme.transitionValue};
    color: ${p => p.theme.colors.accent};
    font-weight: 500;

    &:visited {
        color: ${p => p.theme.colors.accent};
        opacity: 0.85;
        transform: translateY(-1px);
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        color: ${p => p.theme.colors.accentHover};
    }

    ${p => p.underline && `
        text-decoration: underline;
    `};

    ${p => p.arrowed && `
        &:after {
            display: block;
            transition: ${p.theme.transitionValue};
            border-right: 2px solid ${p.theme.colors.accent};
            border-top: 2px solid ${p.theme.colors.accent};
            position: absolute;
            top: 50%;
            right: 0;
            width: 6px;
            height: 6px;
            margin-right: -15px;
            margin-top: -1.5px;
            content: "";
            opacity: .5;
            transform: rotate(45deg);
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover:after {
            border-color: ${p.theme.colors.accentHover};
            margin-right: -20px;
            opacity: 1;
        }
    `};
    
    ${commonStyles};
`;

export default Anchor;
