import { Link } from "gatsby";
import styled from "@emotion/styled";
import { commonStyles, CommonProps } from "@styles/common";

const GatsbyLink = styled(Link)<CommonProps>`

    ${commonStyles};
`;

export default GatsbyLink;
